import React from 'react';
import { BOARDS_TAB_KEY, ERROR_STATE } from '../../../../common/constants/tab-content';
import ErrorState from '../../../../common/ui/error-state';
import { EndInteractionsTracker, useFireErrorOrSuccessAnalytics } from '../../../../common/utils';

type BoardsErrorStateProps = {
	error: Error | null;
};

export const BoardsErrorState = ({ error }: BoardsErrorStateProps) => {
	useFireErrorOrSuccessAnalytics(false, error, false, BOARDS_TAB_KEY);

	return (
		<>
			<ErrorState />
			<EndInteractionsTracker transitionKey={BOARDS_TAB_KEY} renderState={ERROR_STATE} />
		</>
	);
};
