import React, { useCallback, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type LoadNewActivityButtonProps = {
	configId: string | undefined;
	onUpdate: () => void;
};

export const LoadNewActivityButton = ({ configId, onUpdate }: LoadNewActivityButtonProps) => {
	const { formatMessage } = useIntl();

	const onClick = useCallback(
		(e: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'loadNewActivityButton', {
				itemTab: configId,
			});
			return onUpdate();
		},
		[configId, onUpdate],
	);

	return (
		<Box xcss={wrapperStyles}>
			<Button appearance="primary" onClick={onClick} spacing="compact">
				{formatMessage(messages.loadMoreButton)}
			</Button>
		</Box>
	);
};

const wrapperStyles = xcss({
	position: 'absolute',
	left: '50%',
	top: token('space.150', '12px'),
	transform: 'translateX(-50%)',
});
