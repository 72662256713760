import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyBasicAuthPostDeprecationUsersBanner = lazy(
	() => import(/* webpackChunkName: "lazy-basic-auth-post-deprecation-users-banner" */ './index'),
	{ ssr: false },
);

export const AsyncBasicAuthPostDeprecationUsersBanner = () => (
	<JSErrorBoundary
		id="homePageYourWorkBasicAuthBannerAsync"
		packageName="jiraHome"
		teamName="jira-cosmos"
	>
		<Placeholder name="auth-post-deprecation-users-banner" fallback={<></>}>
			<LazyBasicAuthPostDeprecationUsersBanner />
		</Placeholder>
	</JSErrorBoundary>
);
