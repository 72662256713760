import React from 'react';
import EmptyNoBoards from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/empty/components/empty-no-boards';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { BOARDS_TAB_KEY, EMPTY_STATE } from '../../../../common/constants/tab-content';
import TabEmptyState from '../../../../common/ui/empty-state';
import { EndInteractionsTracker, useFireErrorOrSuccessAnalytics } from '../../../../common/utils';
import messages from './messages';

export const EmptyState = () => {
	const { formatMessage } = useIntl();
	const imageProps = {
		renderImage: getAkEmptyStateRenderImageFn(EmptyNoBoards),
	};

	useFireErrorOrSuccessAnalytics(false, null, false, BOARDS_TAB_KEY);

	return (
		<>
			<TabEmptyState
				{...imageProps}
				header={formatMessage(messages.emptyStateHeader)}
				headingLevel={2}
				description={formatMessage(messages.emptyStateDescription)}
				secondaryAction={<></>}
			/>
			<EndInteractionsTracker transitionKey={BOARDS_TAB_KEY} renderState={EMPTY_STATE} />
		</>
	);
};
