import { useMemo } from 'react';
import { toBaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { createHook, createStore } from '@atlassian/react-sweet-state';
import { type Actions, type StoreActions, actions as subscriberActions } from './actions';
import type { ItsmAudienceState } from './types';

export const initialState: ItsmAudienceState = {
	data: undefined,
	isLoading: false,
	error: undefined,
};

export const itsmAudienceStore = createStore<ItsmAudienceState, StoreActions>({
	name: 'ITSMOnboardingAudience',
	initialState,
	actions: subscriberActions,
});

const useItsmAudienceStore = createHook(itsmAudienceStore);

// @ts-expect-error - TS7031 - Binding element 'baseUrl' implicitly has an 'any' type. | TS7031 - Binding element 'fetchFirstProjectAttributes' implicitly has an 'any' type.
const useActionsMemo = ({ baseUrl, fetchFirstProjectAttributes }): Actions =>
	useMemo(
		() => ({
			fetchFirstProjectAttributes: fetchFirstProjectAttributes({ baseUrl }),
		}),
		[baseUrl, fetchFirstProjectAttributes],
	);

const useStateMemo = ({ data, isLoading, error }: ItsmAudienceState): ItsmAudienceState =>
	useMemo(
		() => ({
			data,
			isLoading,
			error,
		}),
		[data, isLoading, error],
	);

export const useItsmAudience = (): [ItsmAudienceState, Actions] => {
	const baseUrl = toBaseUrl('');
	const [state, actions] = useItsmAudienceStore();
	const memoizedActions = useActionsMemo({ ...actions, baseUrl });
	const memoizedState = useStateMemo(state);
	return useMemo(() => [memoizedState, memoizedActions], [memoizedActions, memoizedState]);
};
