import React from 'react';
import { styled } from '@compiled/react';
import EmptyState from '@atlaskit/empty-state';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import SomethingWrong from './assets/something-wrong.svg';
import messages from './messages';

const emptyStateMaxImageWidth = 300;
const emptyStateMaxImageHeight = 250;

export const ErrorState = () => {
	const { formatMessage } = useIntl();

	return (
		<ErrorWrapper data-test-id="global-pages.home.common.ui.error-state">
			<EmptyState
				header={formatMessage(messages.errorHeader)}
				description={formatMessage(messages.errorDescription)}
				maxImageWidth={emptyStateMaxImageWidth}
				maxImageHeight={emptyStateMaxImageHeight}
				imageUrl={SomethingWrong}
			/>
		</ErrorWrapper>
	);
};

export default ErrorState;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: token('space.1000', '80px'),
	width: '100%',
});
