import React from 'react';
import get from 'lodash/get';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { MAX_ITEMS_PER_PAGE } from '../../common/constants/tab-content';
import type { FetchAssignedData } from '../../common/types/assigned';
import type { DataIssueResult, Issue } from '../../common/types/gira';
import type { Item } from '../../common/types/item';
import type {
	InjectedItemProviderProps,
	ItemProviderData,
	ItemProviderDataSection,
} from '../../common/types/item-provider';
import ItemProvider from '../item-provider';

export const queryUrl = '/rest/gira/1/';
const operationName = 'homeAssignedIssuesQuery';
const query = `query ${operationName}($first: Int!, $jql: String, $useIssueService: Boolean!, $isMaxResultsLimitEnabled: Boolean!) {
    issues(first: $first, jql: $jql, useIssueService: $useIssueService, isMaxResultsLimitEnabled: $isMaxResultsLimitEnabled) {
      edges {
        node {
          issueId
          issuekey {
            stringValue
          }
          summary {
            textValue
          }
          project {
            id
            name
          }
          status {
            statusCategoryId
            name
          }
          issuetype {
            id
            name
            iconUrl
          }
        }
      }
      totalCount
    }
  }`;
const jqlQuery = 'assignee = currentUser() AND statusCategory != 3 ORDER BY statusCategory DESC';
export const transformResult = (result?: DataIssueResult): ItemProviderData | undefined => {
	if (
		typeof result === 'undefined' ||
		typeof result.data === 'undefined' ||
		typeof result.data.issues === 'undefined'
	) {
		return undefined;
	}

	const {
		data: { issues: issueList },
	} = result;
	const { edges, totalCount } = issueList;
	if (Array.isArray(edges) === false) {
		return { sections: [], total: 0 };
	}
	const issues = edges.map((edge) => edge.node);
	const sections = [...new Set(issues.map((item) => get(item, 'status.name')))];
	return {
		total: totalCount,
		sections: sections.map(
			(status): ItemProviderDataSection => ({
				header: status,
				items: issues
					.map((issue: Issue): Item | undefined =>
						// @ts-expect-error - TS2322 - Type '{ id: number; title: any; entityId: number; subtitle: any; avatarUrl: any; url: string; status: any; statusField: { id: any; name: any; statusCategory: { id: any; }; }; attributes: { containerId: any; containerName: any; issueTypeId: any; issueTypeName: any; parentType: string; }; type: string; favourite: undefined;...' is not assignable to type 'Item | undefined'.
						get(issue, 'status.name') === status
							? {
									id: get(issue, 'issueId'),
									title: get(issue, 'summary.textValue'),
									entityId: get(issue, 'issueId'),
									subtitle: get(issue, 'issuekey.stringValue'),
									avatarUrl: get(issue, 'issuetype.iconUrl'),
									url: `/browse/${get(issue, 'issuekey.stringValue')}`,
									status: get(issue, 'status.name'),
									statusField: {
										id: get(issue, 'status.statusId'),
										name: get(issue, 'status.name'),
										statusCategory: {
											id: get(issue, 'status.statusCategoryId'),
										},
									},
									attributes: {
										containerId: get(issue, 'project.id'),
										containerName: get(issue, 'project.name'),
										issueTypeId: get(issue, 'issuetype.id'),
										issueTypeName: get(issue, 'issuetype.name'),
										parentType: 'project',
									},
									type: 'issue',
									favourite: undefined,
								}
							: undefined,
					)
					.filter(Boolean),
			}),
		),
	};
};

export const fetchData: FetchAssignedData = async (): Promise<DataIssueResult> => {
	const result = await fetchJson(queryUrl, {
		method: 'POST',
		body: JSON.stringify({
			operationName,
			query,
			variables: {
				first: MAX_ITEMS_PER_PAGE,
				jql: jqlQuery,
				useIssueService: true,
				isMaxResultsLimitEnabled: true,
			},
		}),
	});

	if (typeof result === 'undefined') {
		throw Error('Could not retrieve issues assigned to you');
	}
	return result;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: InjectedItemProviderProps) => {
	const { userProviderData, ...otherProps } = props;
	return <ItemProvider {...otherProps} />;
};
