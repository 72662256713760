import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { Project } from '../../../../common/types';
import { messages } from '../../messages';

export const getCoreProjectDefaultBoard = (project: Project, baseUrl: string, intl: IntlShape) => ({
	heading: intl.formatMessage(messages.boardsHeading),
	items: [
		{
			content: intl.formatMessage(messages.coreBoardTitle),
			href: `/projects/${project.key}/board`,
			type: 'core',
		},
	],
});
