import { useState, useEffect, useMemo } from 'react';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { Project, DeepDiveItem } from '../../../../common/types';
import { getBoardUrl } from '../../../../common/utils';
import { messages } from '../../messages';
import type { GetBoardsResponse } from './types';

export const createUrl = (baseUrl: string, projectKey: string) =>
	`/rest/agile/1.0/board?projectLocation=${projectKey}&startAt=0&deep-dive-getboards=1`;

const fetchBoards = (baseUrl: string, projectKey: string): Promise<GetBoardsResponse> =>
	fetchJson(createUrl(baseUrl, projectKey));

type BoardResults = {
	data?: {
		[project: string]: DeepDiveItem;
	};
};

const initialBoardState = (softwareProjects: Project[]) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	softwareProjects.reduce<Record<string, any>>(
		(acc, { id: projectId }) => ({
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			...acc,
			[projectId]: {
				loading: true,
				error: false,
			},
		}),
		{},
	);

const useFetchSoftwareBoards = (recentProjects: Project[]) => {
	const baseUrl = '';
	const { formatMessage } = useIntl();
	const softwareProjects = useMemo(
		() => recentProjects.filter((project) => project.projectType === SOFTWARE_PROJECT),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[JSON.stringify(recentProjects)],
	);
	const [results, setResults] = useState<BoardResults>({
		data: initialBoardState(softwareProjects),
	});
	const heading = formatMessage(messages.boardsHeading);

	useEffect(() => {
		let didCancel = false;
		setResults({ data: initialBoardState(softwareProjects) });

		(async () => {
			await Promise.all(
				softwareProjects.map(async ({ id: projectId, key: projectKey }) => {
					try {
						const { values } = await fetchBoards(baseUrl, projectKey);

						!didCancel &&
							setResults(({ data }) => ({
								data: {
									...data,
									[projectId]: {
										heading,
										items: values.map(({ id, name, type }) => ({
											content: name,
											href: getBoardUrl(baseUrl, projectKey, id.toString(), type),
											type,
										})),
										loading: false,
										error: false,
									},
								},
							}));
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} catch (fetchError: any) {
						trackFetchErrors({
							error: fetchError,
							id: 'fetchSoftwareBoards',

							packageName: 'deepDiveCards',
						});
						!didCancel &&
							setResults(({ data }) => ({
								data: {
									...data,
									[projectId]: {
										loading: false,
										error: true,
									},
								},
							}));
					}
				}),
			);
		})();
		return () => {
			didCancel = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baseUrl, heading, JSON.stringify(softwareProjects)]);

	return results;
};

export default useFetchSoftwareBoards;
