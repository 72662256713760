import { fromUnixTime, getUnixTime } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import type { IntlShape } from '@atlassian/jira-intl';
import type { TimeZone } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	ActivitiesData,
	ActivityItem,
	Contributor as ActivityContributor,
} from '@atlassian/recent-work-client';
import messages from '../../common/messages';
import type { Contributor, Item } from '../../common/types/item';
import type { ItemProviderDataSection } from '../../common/types/item-provider';
import { addSection, getSortedItems } from '../../common/utils';

export const transformActivity = (activity: string, intl: IntlShape) => {
	let result = '';
	switch (activity.toUpperCase()) {
		case 'ASSIGNED':
			result = intl.formatMessage(messages.assignedAction);
			break;
		case 'COMMENTED':
			result = intl.formatMessage(messages.commentedAction);
			break;
		case 'CREATED':
			result = intl.formatMessage(messages.createdAction);
			break;
		case 'EDITED':
			result = intl.formatMessage(messages.editedAction);
			break;
		case 'LIKED':
			result = intl.formatMessage(messages.linkedAction);
			break;
		case 'PUBLISHED':
			result = intl.formatMessage(messages.publishedAction);
			break;
		case 'TRANSITIONED':
			result = intl.formatMessage(messages.transitionedAction);
			break;
		case 'UNASSIGNED':
			result = intl.formatMessage(messages.unassignedAction);
			break;
		case 'UPDATED':
			result = intl.formatMessage(messages.updatedAction);
			break;
		case 'VIEWED':
			result = intl.formatMessage(messages.viewedAction);
			break;
		default:
			break;
	}
	return result;
};

const sortByTimestamp = (a: Item, b: Item) =>
	(b.timestamp && a.timestamp && b.timestamp - a.timestamp) || 0;

export const groupIntoSections = ({
	result,
	timeZone,
	intl,
}: {
	result: Item[];
	timeZone: TimeZone;
	intl: IntlShape;
}): ItemProviderDataSection[] => {
	const getDateForComparison = (item: Item) =>
		typeof item.timestamp === 'number'
			? utcToZonedTime(fromUnixTime(item.timestamp), timeZone)
			: undefined;
	const { todayItems, yesterdayItems, thisWeekItems, thisMonthItems, moreThanAMonthItems } =
		getSortedItems(result, timeZone, intl, getDateForComparison);

	const sections: ItemProviderDataSection[] = [];
	addSection(sections, todayItems.sort(sortByTimestamp), intl.formatMessage(messages.today));
	addSection(
		sections,
		yesterdayItems.sort(sortByTimestamp),
		intl.formatMessage(messages.yesterday),
	);
	addSection(
		sections,
		thisWeekItems.sort(sortByTimestamp),
		intl.formatMessage(messages.inTheLastWeek),
	);
	addSection(
		sections,
		thisMonthItems.sort(sortByTimestamp),
		intl.formatMessage(messages.inTheLastMonth),
	);
	addSection(
		sections,
		moreThanAMonthItems.sort(sortByTimestamp),
		intl.formatMessage(messages.moreThanAMonthAgo),
	);
	return sections;
};

export const extractResponseItems = (response: ActivitiesData, intl: IntlShape): Item[] =>
	response.workedOn.map((item: ActivityItem) => ({
		id: item?.object?.id,
		title: item?.object?.name || '',
		entityId: item?.object?.localResourceId || '',
		subtitle: item?.containers?.[0]?.name,
		metadata: '',
		avatarUrl: item?.object?.icon?.value,
		url: item?.object?.url,
		action: transformActivity(item?.eventType, intl),
		user: {
			avatarUrl: '',
			label: '',
			displayName: '',
		},
		attributes: {
			ownerId: '',
			containerId: '',
			containerName: item?.containers?.[1]?.name,
			issueTypeId: '',
			issueTypeName: '',
			parentType: '',
		},
		timestamp: getUnixTime(new Date(item.timestamp)),
		type: item?.object?.type,
		contributors:
			item?.contributors
				?.map(
					(c: ActivityContributor): Contributor => ({
						accountId: c.accountId,
						active: true,
						name: c.name ?? '',
						avatarUrls: {
							'48x48': c.picture,
							'24x24': c.picture,
							'16x16': c.picture,
							'32x32': c.picture,
						},
						picture: c.picture,
					}),
				)
				.filter(Boolean) ?? [],
		favourite: undefined,
	}));
