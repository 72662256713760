import { useState, useEffect, useCallback, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { useIntl, type IntlShape } from '@atlassian/jira-intl';
import { toTimeZone } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { type ActivitiesData, createActivityClient } from '@atlassian/recent-work-client';
import { MAX_ITEMS_PER_PAGE, PRODUCTS } from '../../common/constants/tab-content';
import type { ItemProviderData } from '../../common/types/item-provider';
import type { FetchRecentActivityData } from '../../common/types/recent-activity';
import type { WorkedOnProviderProps } from '../../types';
import { groupIntoSections, extractResponseItems } from '../utils';

const TIME_ZONE = toTimeZone('UTC');
const POLLING_INTERVAL = 5000;
const isActiveTab = (): boolean => !document.hidden;
const activityClient = createActivityClient('v3', 'jira');

export const fetchData: FetchRecentActivityData = (cloudId: string) =>
	activityClient.fetchActivities(
		[50, 125, 250],
		{
			limit: MAX_ITEMS_PER_PAGE * 2,
			rootContainerIds: [`ari:cloud:platform::site/${cloudId}`],
			products: PRODUCTS,
		},
		['workedOn'],
	);

export const transformResult = (result: ActivitiesData | undefined, intl: IntlShape) => {
	if (result?.workedOn == null) {
		return { sections: [], total: 0 };
	}

	const sections = groupIntoSections({
		result: extractResponseItems(result, intl),
		timeZone: TIME_ZONE,
		intl,
	});

	return { sections, total: result.workedOn.length };
};

const WorkedOnProvider = ({ prefetchResult, providerKey, children }: WorkedOnProviderProps) => {
	const intl = useIntl();
	const cloudId = useCloudId();
	const defaultData = {
		sections: [],
		total: 0,
	};

	const prefetchData = prefetchResult?.data?.tabs[providerKey]?.data;
	const hasPrefetchData = Boolean(prefetchData?.total);
	const [isActiveTabState, setIsActiveTabState] = useState(true);
	const [data, setData] = useState<ItemProviderData>(prefetchData || defaultData);
	const [pollingData, setPollingData] = useState<ItemProviderData>(prefetchData || defaultData);
	const [updateAvailable, setUpdateAvailable] = useState(false);
	const [loading, setLoading] = useState<boolean>(!hasPrefetchData);
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	const [error, setError] = useState<Error | undefined | void>();

	const fetchAndTransformData = useCallback(async () => {
		const response = await fetchData(cloudId);
		return transformResult(response, intl);
	}, [cloudId, intl]);

	useEffect(() => {
		const initalDataLoad = async () => {
			try {
				const results = await fetchAndTransformData();

				results && setData(results);
			} catch (err) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				setError(err as Error);
			} finally {
				setLoading(false);
			}
		};

		!hasPrefetchData && initalDataLoad();
	}, [fetchAndTransformData, hasPrefetchData]);

	const updateData = useCallback(() => {
		setData(pollingData);
		setUpdateAvailable(false);
	}, [pollingData]);

	const backgroundCheckRef = useRef<ReturnType<typeof setInterval> | null>(null);

	const stopPolling = useCallback(() => {
		backgroundCheckRef.current && clearInterval(backgroundCheckRef.current);
		backgroundCheckRef.current = null;
	}, []);

	const startPolling = useCallback(() => {
		stopPolling();

		backgroundCheckRef.current = setInterval(async () => {
			// when window tab gets re-activated
			if (isActiveTab() && !isActiveTabState) {
				setIsActiveTabState(true);

				const results = await fetchAndTransformData();

				if (results && !isEqual(pollingData?.sections, results.sections)) {
					setPollingData(results);
					setUpdateAvailable(true);
				}
			}

			// reset active tab state
			if (!isActiveTab() && isActiveTabState) {
				setIsActiveTabState(false);
			}
		}, POLLING_INTERVAL);
	}, [pollingData, fetchAndTransformData, isActiveTabState, stopPolling]);

	useEffect(() => {
		startPolling();

		return () => stopPolling();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startPolling]);

	return children({
		data,
		loading,
		reloading: false,
		error,
		updateAvailable,
		update: updateData,
	});
};

export default WorkedOnProvider;
