import React, { useMemo, useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { YourWorkItemListSkeleton } from '@atlassian/jira-skeletons/src/ui/your-work/index.tsx';
import {
	MAX_ITEMS_PER_PAGE,
	WORKEDON_TAB_KEY,
	SUCCESS_STATE,
	ERROR_STATE,
	EMPTY_STATE,
} from '../../../common/constants/tab-content';
import type { ItemProviderDataSection } from '../../../common/types/item-provider';
import type { TabProps } from '../../../common/types/tabs';
import EmptyState from '../../../common/ui/empty-state';
import ErrorState from '../../../common/ui/error-state';
import ItemList from '../../../common/ui/item-list';
import ReloadingSpinner from '../../../common/ui/reloading-spinner';
import { TabFooter } from '../../../common/ui/tab-footer/styled.tsx';
import { ButtonWithAnalytics, LinkWithAnalytics } from '../../../common/ui/with-analytics';
import {
	trimItemsBySection,
	EndInteractionsTracker,
	useFireErrorOrSuccessAnalytics,
} from '../../../common/utils';
import RecentlyViewedImageUrl from './assets/migration-logo.svg';
import messages from './messages';

export const sumLength = (initialLength: number, section: ItemProviderDataSection) =>
	initialLength + section.items.length;

const WorkedOnTab = (props: TabProps) => {
	const {
		itemProviderResult: { data, error, loading, reloading },
		onLoadMoreClick,
	} = props;

	const { formatMessage } = useIntl();

	useFireErrorOrSuccessAnalytics(!!data.total, error, loading, 'workedOn');

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const analyticsEvent = useMemo(
		() =>
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'link',
			}),
		[createAnalyticsEvent],
	);

	const handleClick = useCallback(() => {
		// BLU-1095
		fireUIAnalytics(analyticsEvent, 'recentWorkItem', { pageLocation: 'home' });
	}, [analyticsEvent]);

	if (error && !data.total) {
		return (
			<>
				<ErrorState />
				<EndInteractionsTracker transitionKey={WORKEDON_TAB_KEY} renderState={ERROR_STATE} />
			</>
		);
	}

	if (loading) {
		return <YourWorkItemListSkeleton />;
	}

	if (data.sections.length === 0) {
		return (
			<>
				<EmptyState
					header={formatMessage(messages.emptyStateHeader)}
					description={formatMessage(messages.emptyStateDescription)}
					imageUrl={RecentlyViewedImageUrl}
					primaryAction={
						<LinkWithAnalytics
							actionSubjectId="recentlyViewedBrowseProjectsButton"
							appearance="primary"
							buttonLink
							href="/jira/projects"
						>
							{formatMessage(messages.viewAllProjects)}
						</LinkWithAnalytics>
					}
				/>
				<EndInteractionsTracker transitionKey={WORKEDON_TAB_KEY} renderState={EMPTY_STATE} />
			</>
		);
	}

	const numberOfItemsInAllSections = data.sections.reduce(sumLength, 0);

	const footer =
		numberOfItemsInAllSections > MAX_ITEMS_PER_PAGE && onLoadMoreClick ? (
			<TabFooter>
				<ButtonWithAnalytics
					actionSubjectId="recentlyViewedLoadMoreButton"
					onClick={onLoadMoreClick}
				>
					{formatMessage(messages.loadMore)}
				</ButtonWithAnalytics>
			</TabFooter>
		) : null;

	return (
		<>
			{reloading && <ReloadingSpinner />}
			<ItemList
				sections={trimItemsBySection(props.maxItems, data.sections)}
				onItemClick={handleClick}
			/>
			{footer}
			<EndInteractionsTracker transitionKey={WORKEDON_TAB_KEY} renderState={SUCCESS_STATE} />
		</>
	);
};

export default WorkedOnTab;
