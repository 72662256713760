import React, { type ComponentType } from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import { StartExperiencesOnce } from '@atlassian/jira-servicedesk-itsm-onboarding-common/src/ui/start-experiences-once/index.tsx';
import { yourWorkFirstStepExperience } from '../../experiences';

const preloadComponent = () =>
	import(/* webpackChunkName: "itsm-your-work-tour" */ './index').then(
		(exportedModule) => exportedModule.YourWorkTour,
	);
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const YourWorkTourInternal = lazy(() => preloadComponent());

export const YourWorkTour: ComponentType = () => (
	<Placeholder name="itsm-your-work-tour" fallback={<></>}>
		{/* We start measuring the experience from the moment that lazy suspense allows the tour to start loading */}
		<StartExperiencesOnce experiences={[yourWorkFirstStepExperience]} />
		<YourWorkTourInternal />
	</Placeholder>
);
