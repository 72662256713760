import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	loadMoreButton: {
		defaultMessage: 'Load new activity',
		// eslint-disable-next-line jira/i18n/id-named-by-package -- Fixing this is out of scope for the PR the rule is blocking
		id: 'home.tab-container.load-more-button',
		description: 'Text of a button on the your work page which loads the latest data',
	},
});
