import React, { type ReactNode } from 'react';
import { ButtonGroup } from '@atlaskit/button';
import StarredEmptyStateImage from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/empty/components/empty-state-starred';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import TabEmptyState from '../../../../common/ui/empty-state';
import { LinkWithAnalytics } from '../../../../common/ui/with-analytics';
import messages from './messages';

type Props = {
	shouldShowBoardsLink: boolean;
	shouldShowServiceDeskLink: boolean;
	shouldShowPlansLink: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Button = (props: { href: string; children: ReactNode; extraAttributes: any }) => (
	<LinkWithAnalytics
		actionSubjectId="emptyStateLink"
		appearance="default"
		spacing="compact"
		buttonLink
		{...props}
	/>
);

export const EmptyState = (props: Props) => {
	const { shouldShowBoardsLink, shouldShowServiceDeskLink, shouldShowPlansLink } = props;

	const { formatMessage } = useIntl();

	return (
		<TabEmptyState
			maxImageWidth={250} // need to inforce width as the image is too big
			renderImage={getAkEmptyStateRenderImageFn(StarredEmptyStateImage)}
			header={formatMessage(messages.header)}
			headingLevel={2}
			description={formatMessage(messages.description)}
			secondaryAction={
				<ButtonGroup>
					<Button
						extraAttributes={{ whichButton: 'project' }}
						href="/jira/projects?page=1&selectedCategory=all&selectedProjectType=business%2Csoftware&sortKey=name&sortOrder=ASC"
					>
						{formatMessage(messages.project)}
					</Button>
					{shouldShowBoardsLink && (
						<Button extraAttributes={{ whichButton: 'board' }} href="/jira/boards">
							{formatMessage(messages.board)}
						</Button>
					)}

					{shouldShowServiceDeskLink && (
						<Button
							extraAttributes={{ whichButton: 'service_desk' }}
							href="/jira/projects?page=1&selectedCategory=all&selectedProjectType=service_desk&sortKey=name&sortOrder=ASC"
						>
							{formatMessage(messages.serviceProject)}
						</Button>
					)}
					<Button href="/jira/filters" extraAttributes={{ whichButton: 'filter' }}>
						{formatMessage(messages.filter)}
					</Button>
					<Button href="/jira/dashboards" extraAttributes={{ whichButton: 'dashboard' }}>
						{formatMessage(messages.dashboard)}
					</Button>
					{shouldShowPlansLink && (
						<Button
							extraAttributes={{ whichButton: 'plan' }}
							href="/jira/plans?page=1&orderBy=title&sortDirection=ASC&maxResults=25"
						>
							{formatMessage(messages.plan)}
						</Button>
					)}
				</ButtonGroup>
			}
		/>
	);
};

export default EmptyState;
